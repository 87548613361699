import queryString from 'query-string';

/**
 * Prepare query String
 * @param params
 */
export const prepareQueryString = (params: object): string => {
  return queryString.stringify(params);
};

/**
 * Prepare url with query string
 * @param url
 * @param params
 */
export const prepareUrlWithQueryString = (url: string, params: object): string => {
  return url + '?' + prepareQueryString(params);
};
